import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import UIkit from 'uikit';
const PROGRAM_CONST = {
  PROGRAM_CONFIG_SUCCESS: 'Program Configured Successfully.',
  TYPE_SITE_PANEL: 'sitePanel',
  LABEL_SITE: 'site/session',
  LABEL_PROGRAM: 'program',
  LABEL_MANAGER: 'manager',
  LABEL_SESSION_MANAGER: 'sessionManager',
  LABEL_SELECT_SITE: 'Select Site/Session',
  LABEL_AFTER_PROGRAM_START_DATE: 'After Program Start Date',
  LABEL_DAYS: 'days',
  SAVE_BTN_CLICK: 'saveButtonClick',
  NUM_OF_SCHOLARS: 'numberScholars',
  ANSWER_YES: 'YES',
  ANSWER_NO: 'NO',
  QUESTION_VIEW: 'questionView',
  LIST_VIEW: 'listView',

  LIST_SESSION_MANAGER: 'Session Manager List' ,
  MY_LIST: 'My List', 
  FULL_LIST: 'Full List',
    
    


  ACTION_RESTART: 'Restart', 
  ACTION_FILLING: 'filling',
  ACTION_LIST_PREVIEW: 'list-preview',
    

  KEY_ID: 'id',
  KEY_QUSTION_ID: 'questionId',
  KEY_TITLE: 'title',
  KEY_DESCRIPTON: 'description',
  KEY_TASKS: 'tasks',
  KEY_CMS_ANS_ID: 'cmsAnswerId',
  KEY_STATUS: 'status',
  KEY_CREATED_BY: 'createdBy',
  KEY_CREATED_AT: 'createdAt',
    
  ROUTE_CONFIG_PROGRAM: 'ConfigureProgram',
  LABEL_CONFIG_PROGRAM: 'Configure Your Program',
  LABEL_CRATE_NEW_PROGRAM: 'Create New Program',


  ELEMENTS_INVITE_MANAGER_SENT: 'invite-manager-sent',
    


  ERR_PHONE_NUMBER: 'Please fill 10digits PhoneNumber!!',
  ERR_REQUIRED_FIELD_MISSING: 'Please Fill all required fields!!',
  ERR_DUPLICAT_SIDE: 'Hmm. You already have a Site/Session with this name. Try using a different name, instead!',

  ROLE_SESSION_ADMIN: 'SessionAdmin',
  ROLE_PROGRAM_ADMIN: 'ProgramAdmin'   
};
let taskListUp: any = [];
let taskRemoveUpIndex: any;
let newMonths: any = [];
let updateStartMonth: string = '';
let updateListType: string = '';
@Component({
  name: 'task-list'
})
export class TaskList extends Vue {
    @Prop()
    tasksList: any;
    @Prop()
    tabSelection: any;
    private objScreenText: ScreenText = new ScreenText();
    private taskRequiredKeys: string[] = ['title', 'description', 'startDate', 'isRemoved', 'assignedTo'];
    private listTabs: object[] = APP_CONST.TASK_SHOW_TABS;
    private newTaskList: any = [];
    private removedTaskList: any = [];
    private getMonths: any = APP_CONST.GET_MONTHS;
    private monthsToShow: any = [];
    private listType: string = 'SessionAdmin';
    public taskRemoveIndex: any;
    public dontShowCheck: boolean = false;
    public counter: number = 0;
    public updateTasksFill: any = [];
    public updateId: any = 0;
    public alreadyRemovedTasks:any = [];
    public lastMonth: string = '';

    get updateTabSelection() {
      return this.tabSelection;
    }

    get updateTaskList() {
      this.updateTaskListGetter();
      return this.tasksList;
    }

    updateTaskListGetter(){
      this.counter++;
      if (this.counter === 1) {
        setTimeout(() => {
          this.newTaskList = [];
          const deriveMonth = (startDate: string) => {
            if(startDate){
              return this.getMonths[new Date(startDate).getMonth()].monthName;
            }
          };
          let itemObjArray: any[] = [];
          this.tasksList.forEach((item: any, index: number) => {
            const itemObj:any = {
              id: item.id
                ? item.id
                : index,
              cmsTaskId:item.cmsTaskId,
              openStatus: false,
              title: item.title,
              description: (item.description&&typeof (item.description) === 'string')
                ? item.description
                : item.description
                  ?item.description.content[0].content[0].value
                  :'',
              startDate: item.taskStartDate,
              endDate: item.taskEndDate,
              isRemoved: item.isRemoved
                ? item.isRemoved
                :false,
              assignedTo: item.assignedto,
              startMonth: deriveMonth(item.taskStartDate),
              questionId: item.questionid,
              removeClick: false,
              answerCMSId: item.answerCMSId
            };
            item.type && (itemObj['type'] = item.type);
            itemObjArray.push(itemObj);

          });
          itemObjArray = itemObjArray.filter((item:any) =>item.isRemoved!== true);
          this.alreadyRemovedTasks = itemObjArray.filter((item:any) => item.isRemoved === true);
          const newTaskList: any[] = [];
          this.getMonths.forEach((item: any) => {
            const dataFilter = itemObjArray.map((itemObj: any) => {
              const startMonth = itemObj.startMonth && (itemObj.startMonth === item.monthName);
              if (startMonth) {
                this.lastMonth = itemObj.startMonth;
                const obj = {
                  monthName: item.monthName,
                  assignedto: itemObj.assignedTo
                };
                this.monthsToShow.push(obj);
                return itemObj;
              }
            }).filter(function (element) {
              return element !== undefined;
            });
            newTaskList.push(...dataFilter);
          });
          this.newTaskList = newTaskList;

          this.selectListButton(APP_CONST.ZERO, PROGRAM_CONST.LIST_SESSION_MANAGER);
        }, 500);
      }
        
    }

    @Watch('updateTaskList', { deep: true })
    checkForTasklist(data: any) {
      if (this.counter === 1) {
        const newTaskList = [...this.newTaskList];
        this.newTaskList = newTaskList;
      }
    }
  
    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    public defaultMonthTaskOpen(type: string) {
      const openTopTask = (topTaskIndex: number) => {
        this.newTaskList.forEach((task: any, taskIndex: number) => {
          if (taskIndex === topTaskIndex) {
            task.openStatus = true;
          }
          else {
            task.openStatus = false;
          }
        });
      };
      if (type !== '' && this.monthsToShow.length > 0) {
        const indexNew = this.monthsToShow.findIndex((item: any) => item.assignedto === type);
        this.getMonths.forEach((month: any, index: number) => {
          const checkMonth = this.monthsToShow[indexNew] && (this.monthsToShow[indexNew].monthName === month.monthName);
          if (checkMonth) {
            this.getMonths[index].openStatus = true;
          }
          else {
            this.getMonths[index].openStatus = false;
          }
        });
        openTopTask(indexNew);
      }
      else {
        if (this.monthsToShow.length > 0) {
          const monthGet = this.monthsToShow[APP_CONST.ZERO].monthName;
          this.getMonths.forEach((month: any, index: number) => {
            if (monthGet === month.monthName) {
              this.getMonths[index].openStatus = true;
            }
            else {
              this.getMonths[index].openStatus = false;
            }
          });
          openTopTask(APP_CONST.ZERO);
        }

      }

    }

    public selectListButton(listTabIndex: number, buttonType: string) {
      switch (buttonType) {
        case PROGRAM_CONST.LIST_SESSION_MANAGER:
          this.listType = PROGRAM_CONST.ROLE_SESSION_ADMIN;
          this.defaultMonthTaskOpen(this.listType);
          break;
        case PROGRAM_CONST.MY_LIST:
          this.listType = PROGRAM_CONST.ROLE_PROGRAM_ADMIN;
          this.defaultMonthTaskOpen(this.listType);
          break;
        case PROGRAM_CONST.FULL_LIST:
          this.listType = '';
          this.defaultMonthTaskOpen(this.listType);
          break;
      }
      this.listTabs.forEach((listTab: any, listIndex: number) => {
        if (listIndex === listTabIndex) {
          listTab.buttonStatus = true;
        }
        else {
          listTab.buttonStatus = false;
        }
      });

    }

    public selectTaskAssignee(taskId: number | string, taskAssignee: string, monthIndex: any) {
      if (taskAssignee !== '') {
        let taskIndex = 0;
        this.newTaskList.forEach((task: any, taskNumber: number) => {
          if (task.id === taskId) {
            task.assignedTo = taskAssignee;
            updateStartMonth = task.startMonth;
            taskIndex = taskNumber;
          }
        });
        const getNewMonths = [...this.monthsToShow];
        getNewMonths[taskIndex].assignedto = taskAssignee;
        getNewMonths[taskIndex].monthName = updateStartMonth;
        this.monthsToShow = getNewMonths;
      }
      else {
        if (this.dontShowCheck) {
          this.newTaskList.forEach((task: any) => {
            if (task.id === taskId) {
              updateStartMonth = task.startMonth;
              updateListType = task.assignedTo;
            }
          });
          this.taskRemoveIndex = taskId;
          this.newTaskList = [...this.newTaskList];
          taskListUp = this.newTaskList;
          taskRemoveUpIndex = this.taskRemoveIndex;
          newMonths = this.monthsToShow;
          this.removeTask();
        }
        else {
          this.newTaskList.forEach((task: any) => {
            if (task.id === taskId) {
              task.removeClick = true;
              updateStartMonth = task.startMonth;
              updateListType = task.assignedTo;
            }
          });
          this.taskRemoveIndex = taskId;
          this.newTaskList = [...this.newTaskList];
          taskListUp = this.newTaskList;
          taskRemoveUpIndex = this.taskRemoveIndex;
          newMonths = this.monthsToShow;
        }
      }

    }

    public removeTask() {

      let newTaskList: any = [];
      newTaskList = taskListUp;

      const removeIndex = taskRemoveUpIndex;
      const taskIndex = newTaskList.findIndex((item: any) => item.id == removeIndex);
      let monthIndex = 0;
      if (this.listType !== '') {
        monthIndex = taskIndex;
      }
      else {
        monthIndex = taskIndex;
      }
      newTaskList[taskIndex].isRemoved = true;
      this.removedTaskList.push(newTaskList[taskIndex]);
      newTaskList.splice(taskIndex, 1);
      this.newTaskList = newTaskList;
      const newMonthsToShow = newMonths;
      newMonthsToShow.splice(monthIndex, 1);
      this.monthsToShow = newMonthsToShow;
      if(UIkit.offcanvas('#modal-remove-list')){
        UIkit.offcanvas('#modal-remove-list').hide();
      }
    }

    beforeUpdate() {
      const checkTasks = this.newTaskList.length > 0 || this.removedTaskList.length > 0 || this.alreadyRemovedTasks.length >0;
      if (checkTasks) {
        const combinedTaskList = [...this.newTaskList, ...this.removedTaskList,...this.alreadyRemovedTasks];
        this.$emit('onTaskListUpdate', combinedTaskList);
      }
    }

    beforeDestroy() {
      this.resetTaskProps();
    }

    resetTaskProps(){
      this.counter = 0;
      this.taskRemoveIndex = 0;
      this.newTaskList = [];
      this.monthsToShow = [];
      this.listType = PROGRAM_CONST.ROLE_SESSION_ADMIN;
      this.dontShowCheck = false;
      taskListUp = [];
      taskRemoveUpIndex = 0;
      newMonths = [];
      updateStartMonth = '';
      updateListType = '';
    }

    public toggleMonthTask(monthIndex: number) {
      this.getMonths.forEach((item: any, index: number) => {
        if (index === monthIndex) {
          item.openStatus = item.openStatus
            ? false
            : true;
          setTimeout(() => {
            const indexToScroll = item.index
              ? (item.index - 1)
              : item.index;
            const scrollUpId = this.getMonths[indexToScroll].monthName + '_' + indexToScroll;
            const ele : any = document.getElementById(scrollUpId);
            if(ele){
              ele.scrollIntoView(true);
            }
          });
        }
        else {
          item.openStatus = false;
        }
      });
      this.newTaskList.forEach((task: any) => {
        task.openStatus = false;
      });
    }

    public toggleTaskItem(taskIndex: number) {
      const openStatus = this.newTaskList[taskIndex].openStatus;
      this.newTaskList.forEach((task: any, taskNumber: number) => {
        if (taskIndex === taskNumber) {
          openStatus
            ? task.openStatus = false
            : task.openStatus = true;
        }
        else {
          task.openStatus = false;
        }
      });
    }

    public dontShowCheckCall() {
      this.dontShowCheck = true;
    }

    public cancelRemove() {
      UIkit.offcanvas('#modal-remove-list').hide();
      this.newTaskList.forEach((task: any) => {
        if (task.id === taskRemoveUpIndex) {
          task.removeClick = false;
        }
      });
    }

    closeModal(){
      UIkit.offcanvas('#modal-remove-list').hide();
    }


}